import React from "react"

import Seo from "../../components/seo"
import About from "../../components/Content/About"

export default props => (
  <React.Fragment>
    <Seo
      title={`Leading tour operator in Nicaragua`}
      description={`We are a Full-Service Tour Operator and Destination Management Company (DMC)`}
    />
    <About location={props.location} langKey={props.pageContext.langKey} />
  </React.Fragment>
)
