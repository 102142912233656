import React from "react"
import Layout from "../../layout"
import PagesHero from "../../pages"
import Section from "../../section"
import Testimonials from "../../testimonials"
import BoxCard from "../../boxCard"
import ParallaxContainer from "../../ParallaxContainer"
import { colors } from "../../../utils/presets"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import PrimaryTitle from "../../PrimaryTitle"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"

import { Parallax } from "react-scroll-parallax"

/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"

const About = props => {
  const Counter = styled.h3({
    fontSize: 48,
    color: colors.SKYBLUE,
    fontWeight: `600`,
  })

  const data = useStaticQuery(graphql`
    query {
      aboutImg: file(name: { eq: "about-banner" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <Layout location={props.location}>
        <PagesHero>
          <Section>
            <div className="has-text-centered mb-6">
              <PrimaryTitle textId="AboutUs" />

              <FormattedMessage id="AboutSubText" />
            </div>
            <ParallaxContainer>
              <Parallax y={[-25, 25]} tagOuter="figure">
                <Img
                  alt={`About Us`}
                  fluid={data.aboutImg.childImageSharp.fluid}
                />
              </Parallax>
            </ParallaxContainer>
            <section
              css={{
                position: `relative`,
                top: `-120px`,
              }}
              className={`columns`}
            >
              <div className="column">
                <BoxCard>
                  <hgroup>
                    <h5
                      css={{
                        color: colors.ORANGE,
                      }}
                    >
                      <FormattedMessage id="WhoWeAre" />
                    </h5>
                    <h2 className="sub-title">
                      <FormattedMessage id="LeadingTour" />
                    </h2>
                  </hgroup>

                  <FormattedHTMLMessage id="WhoWeAreContent" />
                </BoxCard>
              </div>
            </section>
            <section
              css={{
                position: `relative`,
                top: `-60px`,
              }}
            >
              <div className="columns">
                <div className="column has-text-centered">
                  <Counter>100K</Counter>
                  <FormattedMessage id="HappyCustomers" />
                </div>
                <div className="column has-text-centered">
                  <Counter>80+</Counter>
                  <FormattedMessage id="ToursAndPackages" />
                </div>
                <div className="column has-text-centered">
                  <Counter>6</Counter>
                  <FormattedMessage id="ExperienceYears" />
                </div>
                <div className="column has-text-centered">
                  <Counter>100%</Counter>
                  <FormattedMessage id="Quality" />
                </div>
              </div>
            </section>
            <section>
              <div className="columns">
                <div className="column">
                  <BoxCard>
                    <hgroup>
                      <h5
                        css={{
                          color: colors.ORANGE,
                        }}
                      >
                        <FormattedMessage id="OurMission" />
                      </h5>
                      <h2 className="sub-title">
                        <FormattedMessage id="OurMissionTitle" />
                      </h2>
                    </hgroup>
                    <p>
                      <FormattedMessage id="OurMissionDesc" />
                    </p>
                  </BoxCard>
                </div>
                <div className="column">
                  <BoxCard>
                    <hgroup>
                      <h5
                        css={{
                          color: colors.ORANGE,
                        }}
                      >
                        <FormattedMessage id="OurVision" />{" "}
                      </h5>
                      <h2 className="sub-title">
                        <FormattedMessage id="OurVisionTitle" />{" "}
                      </h2>
                    </hgroup>
                    <p>
                      <FormattedMessage id="OurVisionDesc" />{" "}
                    </p>
                  </BoxCard>
                </div>
              </div>
            </section>
          </Section>
          <br />
          <Testimonials node_locale={props.langKey} />
        </PagesHero>
      </Layout>
    </React.Fragment>
  )
}

export default About
